import React from "react";

function Faq() {
  return (
    <div className="about">
      <div class="container">
        <div class="row align-items-center my-5">
         
          <div class="col-12">
            <h1 class="font-weight-light">FAQ</h1>
              <div class="fs-4 pt-5">
                <b>How does this work?</b><br/>
                LamboDAO is pooling together money to buy a Lamborghini in major cities around the world.
                Participants will get to drive the vehicle based on their allocation in the pool. <br/><br/> 
                By participating in the DAO, you are getting a time share to drive the Lambo. Please note that you are not receiving a fractional ownership of the vehicle as this is not a realistic option from an administrative standpoint. 
                <br/><br/>
                <b>How much does it cost to join?</b><br/>
                Only <b>1 ETH</b>. Participants will receive an ERC-721 token that will serve as the proof of membership. 
                <br/><br/>
                <b>How many people can participate?</b><br/>
                There are 90 tokens created for each location. This means there can be 90 participants max. at each location. Participants can buy more than 1 token as well.
                <br/><br/>
                <b>How much can I drive?</b><br/>
                Each token will give you 5 days of driving experience that you can use anytime within the next 5 years depending on the availability. Token holders will use a booking platform to reserve the Lambo in advance.
                <br/><br/>
                <b>Are there any requirements?</b><br/>
                A valid driver's license and a security deposit when taking possession of the vehicle.
                <br/><br/>
                <b>Are there any other costs?</b><br/>
                Insurance and maintenance costs are included in the DAO membership. Just fill up the tank and drive! 
                <br/><br/>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;