import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  Faq,
  London,
  Paris,
} from "./components";


ReactDOM.render(
  <Router>
  <Navigation />
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/faq" element={<Faq />} />
    <Route path="/london" element={<London />} />
    <Route path="/paris" element={<Paris />} />
  </Routes>
  <Footer />
</Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
