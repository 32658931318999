import React from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  return (
    <header>
      <nav className="navbar navbar-expand navbar-dark black">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img class="logo" src="./images/logo.png"></img>
          </NavLink>
          <div>
            <ul className="navbar-nav ml-auto fs-5">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Home
                  <span className="sr-only"></span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/faq">
                  FAQ
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Navigation;