import React from "react";

function London() {
  return (
    <div className="home">
      <div class="container">
      
        <p class="mt-4 fs-3">
          Let's pool together money to buy a brand new Lamborghini.
        </p>
        <div class="row my-4">
          <div class="col-xl-3">
              <div>
                <p>
                  Location
                </p>
                <div class="btn-group">
                  <button type="button" class="btn btn-secondary dropdown-toggle btn-lg" data-bs-toggle="dropdown" aria-expanded="false">
                    London
                  </button>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="/">New York</a></li>
                    <li><a class="dropdown-item" href="./london">London</a></li>
                    <li><a class="dropdown-item" href="./paris">Paris</a></li>
                  </ul>
                </div>
              </div>
              <div class="mt-3">
                <p>
                  
                </p>
                <p class="fs-2">
                  
                </p>
                
               
              </div>
          </div>
          <div class="col-xl-8 text-center mx-auto">
            <img class="img-fluid" src="./images/comingsoon.jpg"></img><br/>
            
          </div>
          

        </div>
      </div>
    </div>
  );
}

export default London;