import React from "react";

function Footer() {
  return (
      <footer class="footer mt-auto py-4 black">
        <div class="container">
          
          <p class="m-0 text-center text-white">
          We are NOT affiliated, associated, authorized, endorsed by, or in any way officially connected with Automobili Lamborghini S.p.A. (lamborghini.com)
          </p>
          <div class="text-center my-3">
            <a class="mx-3" href="https://www.twitter.com/LamboDAOcom" target="_blank">TELEGRAM</a>
            <a class="mx-3" href="https://www.twitter.com/LamboDAOcom" target="_blank">TWITTER</a>
            
          </div>
          <p class="m-0 text-center text-white">
            &copy; LamboDAO 2021 - 2022
          </p>
        </div>
      </footer>
  );
}

export default Footer;