import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Navigation, Footer, Home, Faq, London, Paris } from "./components";
function App() {
  return (
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" exact component={() => <Home />} />
          <Route path="/faq" exact component={() => <Faq />} />
          <Route path="/london" exact component={() => <London />} />
          <Route path="/paris" exact component={() => <Paris />} />
        </Routes>
        <Footer />
      </Router>
  );
}

export default App;
